import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { useState } from "react"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faAnchor } from '@fortawesome/free-solid-svg-icons'
import { faShip } from '@fortawesome/free-solid-svg-icons'
import { logo } from '../images/logo.jpg'
import { StaticImage } from "gatsby-plugin-image"
import  background from "../images/blue.jpg"

const link = "block mt-4 mr-4 xl:inline-block xl:mt-0 hover:text-gray-200 hover:underline text-white"


function Header({ siteTitle, siteDescription }) {
  const [isExpanded, toggleExpansion] = useState(false)

  return (
    <div className="flex flex-wrap mb-6 text-white bg-cover" style={{ backgroundImage: `url(${background})` }} >
      <div className="justify-between flex w-full">
        <div className="flex flex-row flex-wrap">

          <Link to="/" className="h-40 w-40">
            <StaticImage
              src="../images/Ron.svg"
              alt="Ron Ditch and Son"
              />
          </Link>
          <div className="flex items-center ml-6">
            <h2 className="text-white">Most experienced charter service on Lake Ontario <br />
            <span>In the family since 1934</span>
            </h2>
          </div>
        </div>
      <div className="block xl:hidden m-8">
        <button
          onClick={() => toggleExpansion(!isExpanded)}
          className="flex items-center px-3 py-2 text-white border border-white rounded hover:text-white hover:border-white mx-6"
        >
          <svg
            className="w-3 h-3 fill-current"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <title>Menu</title>
            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z" />
          </svg>
        </button>
        </div>
      </div>

      <div
        className={`${
          isExpanded ? `block` : `hidden`
        } w-full block flex-grow xl:flex xl:justify-center xl:w-full mt-4 ml-6 mb-6 justify-end`}
      >
        <hr className="mt-6 mx-6 border-gray-600 mb-2" />
        <div className="text-2xl">
          <Link
            to={`/`}
            href="#responsive-header"
            className={link}
          >
            Home
          </Link>
          <Link
            to={`/autobiography`}
            className={link}
          >
            Autobiography
          </Link>
          <Link
            to={`/cormorant`}
            className={link}
          >
            Cormorant
          </Link>
          <Link
            to={`/journal`}
            className={link}
          >
            Journal
          </Link>
          <Link
            to={`/plan`}
            className={link}
          >
            Plan your visit
          </Link>
          <Link
            to={`/news-report`}
            className={link}
          >
            News Report
          </Link>
          <Link
            to={`/pictures`}
            className={link}
          >
            Pictures
          </Link>
          <Link
            to={`/contact`}
            className={link}
          >
            Contact
          </Link>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header
