import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faExternalLinkAlt } from '@fortawesome/free-solid-svg-icons'
import { Link } from "gatsby"
import  background from "../images/blue.jpg"


const link = "hover:text-gray-200 hover:underline text-white mx-2 mt-6 text-1xl justify-between"

function Footer() {

  return (
    <div className="container-fluid bg-gradient-to-r bg-cover  text-white min-w-screen" style={{ backgroundImage: `url(${background})` }}>
      <div className="flex flex-wrap mx-4">
        <div className="flex mx-auto mb-4">
        <div className="flex flex-col mr-12">
            <Link
            to={`/`}
            className={link}
          >
            Home
          </Link>
          <Link
            to={`/autobiography`}
            className={link}
          >
            Autobiography
          </Link>

          <Link
            to={`/cormorant`}
            className={link}
          >
            Cormorant
          </Link>
          <Link
            to={`/journal`}
            className={link}
          >
            Journal
          </Link>
          </div>
          <div className="flex flex-col mr-12">
          <Link
            to={`/plan`}
            className={link}
          >
            Plan your visit
          </Link>
          <Link
            to={`/news-report`}
            className={link}
          >
            News Report
          </Link>
          <Link
            to={`/pictures`}
            className={link}
          >
            Pictures
          </Link>
          </div>
          <div className="flex flex-col mr-12">
          <Link
            to={`/contact`}
            className={link}
          >
            Contact
          </Link>
          </div>
        </div>
        <div
          className="m-6 align-self-center text-white"
          style={{
            textAlign: 'right'
          }}
        >
          <a href="tel:315-938-5234">
            <div className="flex mt-2">
              <div className="ml-auto text-white">
              315-938-5234<br />
              </div>
              <div>
                &nbsp;<sup><FontAwesomeIcon icon={faExternalLinkAlt} className="text-white" /></sup>
              </div>
            </div>
          </a>
          <a href="https://goo.gl/maps/Xfg7t2VHmSEC5ooRA" target="_blank" rel="noopener noreferrer">
            <div className="flex text-white mt-4">
              <div className="ml-auto">
              8405 CORNELL ROAD< br/>
              HENDERSON HARBOR, NY 13650
              </div>
              <div>
                &nbsp;<sup><FontAwesomeIcon icon={faExternalLinkAlt} className="text-white" /></sup>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  )
}


export default Footer
